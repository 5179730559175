// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-escola-js": () => import("./../../../src/pages/a-escola.js" /* webpackChunkName: "component---src-pages-a-escola-js" */),
  "component---src-pages-almoxarifadokka-js": () => import("./../../../src/pages/almoxarifadokka.js" /* webpackChunkName: "component---src-pages-almoxarifadokka-js" */),
  "component---src-pages-almoxarifadoksa-js": () => import("./../../../src/pages/almoxarifadoksa.js" /* webpackChunkName: "component---src-pages-almoxarifadoksa-js" */),
  "component---src-pages-educacao-infantil-js": () => import("./../../../src/pages/educacao-infantil.js" /* webpackChunkName: "component---src-pages-educacao-infantil-js" */),
  "component---src-pages-ensino-fundamental-i-js": () => import("./../../../src/pages/ensino-fundamental-i.js" /* webpackChunkName: "component---src-pages-ensino-fundamental-i-js" */),
  "component---src-pages-ensino-fundamental-ii-js": () => import("./../../../src/pages/ensino-fundamental-ii.js" /* webpackChunkName: "component---src-pages-ensino-fundamental-ii-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

